/**
 * Created by iamso on 23.03.2020.
 */

const HomeTasks = function (home) {
    this.home = home;
    this.actives = undefined;
};

HomeTasks.prototype.add = function (furniture) {
    this.actives.push(furniture);
    furniture.addHighlight();
};

HomeTasks.prototype.isActive = function (furniture) {
    for (const i in this.actives) {
        if (this.actives[i].id === furniture.id) {
            return true;
        }
    }
    return false;
};

HomeTasks.prototype.onFurnitureCompleted = function (furniture) {
    for (const id in this.actives) {
        if (this.actives[id].id === furniture.id) {
            this.actives.splice(id, 1);
            furniture.removeHighlight();
        }
    }
};

HomeTasks.prototype.setActives = function () {
    this.actives = [];
    this.home.getActiveFurniture().forEach((furniture) => {
        this.add(furniture);
    });
};