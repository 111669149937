/**
 * Created by iamso on 24.03.2020.
 */

const HomeTasksIcon = cc.Node.extend({ 
    ctor: function () {
        const styles = cleverapps.styles.HomeTasksIcon;
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        const animation = this.animation = new cleverapps.Spine(bundles.window_icons.jsons.hometask_icon_json);
        animation.setAnimation(0, "book_idle", true);
        this.addChild(animation);

        this.setContentSize(animation.getContentSize());
        animation.setPositionRound(this.width / 2, this.height / 2);
        this.setPositionRound(styles.position);

        const mark = this.mark = new Attention();
        mark.setPositionRound(styles.mark);
        mark.pulse();
        this.addChild(mark);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.onPress.bind(this));

        cleverapps.meta.on("changeStars", this.updateAttention.bind(this), this);
        Homefix.currentHomefix.on("newTask", this.updateAttention.bind(this), this);
        this.updateAttention();
    },

    updateAttention: function () {
        this.mark.setVisible(Homefix.currentHomefix.getFurnitureToUpgrade());
    },

    addNewTaskAnimation: function () {
        this.animation.setAnimationAndIdleAfter("book_newtask", "book_on");
    },

    onPress: function () {
        this.animation.setAnimationAndIdleAfter("book_off", "book_idle");

        cleverapps.focusManager.display({
            focus: "HomeTasksWindow",
            action: function (f) {
                new HomeTasksWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
});

cleverapps.styles.HomeTasksIcon = {
    position: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 120 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 15 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 15 }
        }
    ],

    mark: {
        x: { align: "right" },
        y: { align: "top", dy: 25 }
    }
};
