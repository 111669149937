/**
 * Created by Aleksandr on 30.07.2024
 */

const TravelMapItemView = cc.Node.extend({
    ctor: function (locationId, state, isSelected, animateNext) {
        this._super();

        this.locationId = locationId;
        this.state = state;
        this.isSelected = isSelected;
        this.animateNext = animateNext;

        const styles = cleverapps.styles.TravelMapItemView;

        this.setContentSize(styles);

        const building = this.building = new cleverapps.Spine(bundles.travel_map.jsons[`building_${this.locationId}_json`]);
        building.setPositionRound(styles.building);
        this.addChild(building);

        if (cleverapps.config.debugMode) {
            const debugLocationId = cleverapps.UI.generateImageText(locationId, cleverapps.styles.FONTS.WHITE_TEXT);
            building.addChild(debugLocationId);
            debugLocationId.setPositionRound({
                x: { align: "right" },
                y: { align: "center", dy: 80 }
            });
        }

        const titleBg = this.titleBg = new cc.Scale9Sprite(bundles.travel_map.frames.title_bg);
        titleBg.setPositionRound(styles.titleBg);
        titleBg.setContentSize(styles.titleBg.size);
        this.addChild(titleBg);

        const title = this.title = cleverapps.UI.generateTTFText(`TravelMapWindow.item${this.locationId}`, cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_NAME_TEXT);
        cleverapps.UI.fitToBox(title, styles.titleSize);

        titleBg.addChild(title);
        title.setPositionRound(styles.title);

        this.updateState();
    },

    updateState: function () {
        const state = this.state;

        if (state !== TravelMapItemView.STATES.NEXT && !this.animateNext) {
            this.clickHandler = cleverapps.UI.onClick(this, this.onClick.bind(this));
        }

        if (state === TravelMapItemView.STATES.NEXT) {
            this.titleBg.setColor(new cc.Color(150, 150, 150, 255));
        }

        this.building.setAnimation(0, this.isSelected ? "selected" : state, true);

        if (state === TravelMapItemView.STATES.PASSED) {
            this.addCheckmark();
        }

        if (state === TravelMapItemView.STATES.NEXT) {
            this.addLock();
        }

        if (state === TravelMapItemView.STATES.CURRENT) {
            this.addProgressBar();
        }
    },

    runPassedAnimation: function () {
        this.addCheckmark();
        this.checkmark.scale = 0;

        this.checkmark.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                this.progressBar.updateProgress(100, 100, { animated: true, time: 0.4 });
            }, this),
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.progressBar.receivePrize();
            }, this),
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.progressBar.setCascadeOpacityEnabledRecursively(true);
                this.progressBar.runAction(new cc.Spawn(
                    new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
                    new cc.FadeOut(0.3)
                ));
            }, this),
            new cc.DelayTime(0.3),
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.building.setAnimation(0, "passed", true);
            }, this)
        ));
    },

    runOpenAnimation: function () {
        this.titleBg.runAction(new cc.Sequence(
            new cc.DelayTime(4.5),
            new cc.CallFunc(function () {
                this.lock.setAnimation(0, "open", false);
                this.lock.setCompleteListenerRemove();
            }, this),
            new cc.TintTo(0.3, 255, 255, 255, 255),
            new cc.DelayTime(1.0),
            new cc.CallFunc(function () {
                this.building.setAnimation(0, "selected", true);

                this.addProgressBar();
                this.progressBar.setPercentage(0);
                this.progressBar.setCascadeOpacityEnabledRecursively(true);
                this.progressBar.scale = 0;
                this.progressBar.opacity = 0;
                this.progressBar.runAction(new cc.Spawn(
                    new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
                    new cc.FadeIn(0.3)
                ));
            }, this)
        ));
    },

    addCheckmark: function () {
        const styles = cleverapps.styles.TravelMapItemView;

        this.checkmark = new cc.Sprite(bundles.travel_map.frames.checkmark_png);
        this.checkmark.setPositionRound(
            this.title.x + this.title.width / 2 + this.checkmark.width / 2 + styles.title.icon.offset.x,
            this.titleBg.height / 2 + styles.title.icon.offset.y
        );
        this.titleBg.addChild(this.checkmark);
    },

    addLock: function () {
        const styles = cleverapps.styles.TravelMapItemView;

        this.lock = new cleverapps.Spine(bundles.travel_map.jsons.lock_json);
        this.lock.setPositionRound(
            this.title.x + this.title.width / 2 + this.lock.width / 2 + styles.title.icon.offset.x,
            this.titleBg.height / 2 + styles.title.icon.offset.y
        );
        this.lock.setAnimation(0, "closed");
        this.titleBg.addChild(this.lock);
    },

    addProgressBar: function () {
        const styles = cleverapps.styles.TravelMapItemView;

        const progressBar = this.progressBar = new PrizeBarComponent({
            progressBar: {
                width: styles.bar.width,
                progressImage: styles.bar.type.progress,
                progressBgImage: styles.bar.type.background,
                barText: {
                    text: " "
                }
            },
            reward: cleverapps.meta.logic.getLocationCompleteReward(),
            noMark: true,
            canClick: false
        });

        progressBar.setPercentage(cleverapps.meta.getSelectedLocation().getProgress());
        if (this.animateNext) {
            progressBar.setPercentage(80);
        }
        progressBar.setPositionRound(styles.bar);
        this.addChild(progressBar);
    },

    onClick: function () {
        cleverapps.focusManager.distract({
            focus: "TravelMap.gotoLocation",
            action: function (f) {
                cleverapps.meta.switchLocation(this.locationId);
                cleverapps.meta.gotoLocationScene(f);
            }.bind(this)
        });
    }
});

TravelMapItemView.STATES = {
    PASSED: "passed",
    CURRENT: "current",
    NEXT: "next"
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRAVEL_MAP_ITEM_NAME_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
            size: 3
        },
        shadow: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
            direction: cc.size(2, -2),
            blur: 3
        }
    },

    TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.TravelMapItemView = {
    width: 300,
    height: 350,

    building: {
        x: { align: "center" },
        y: { align: "center" }
    },

    status: {
        icon: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    titleBg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 },
        size: {
            width: 370,
            height: 70
        }
    },

    titleSize: {
        width: 266,
        height: 64
    },

    title: {
        x: { align: "center" },
        y: { align: "center", dy: 3 },

        icon: {
            offset: {
                x: 10,
                y: 3
            }
        }
    },

    bar: {
        width: 220,
        x: { align: "center" },
        y: { align: "bottom", dy: -135 },
        type: {
            progress: bundles.travel_map.frames.bar_progress,
            background: bundles.travel_map.frames.bar_background
        },
        text: {
            font: cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT,
            text: "%from%%",
            dy: 2
        },
        reward: {
            x: { align: "right", dx: 40 },
            y: { align: "center", dy: 10 }
        }
    }
};