/**
 * Created by iamso on 24.03.2020.
 */

const HomeTasksWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.createContent();

        this._super({
            title: {
                text: "HomeTasksWindow.title",
                font: cleverapps.styles.FONTS.SMALL_TITLE_TEXT
            },
            name: "hometaskswindow",
            content: this.content,
            styles: cleverapps.styles.HomeTasksWindow.window
        });
    },

    createContent: function () {
        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(cleverapps.styles.HomeTasksWindow);

        const tasksItems = Homefix.currentHomefix.tasks.actives.map(function (furniture) {
            return this.createTask(furniture);
        }, this);

        if (tasksItems.length) {
            const tasks = new cleverapps.Layout(tasksItems, {
                direction: cleverapps.UI.VERTICAL,
                margin: cleverapps.styles.HomeTasksWindow.margin,
                padding: cleverapps.styles.HomeTasksWindow.padding
            });
            this.wrapInScroll(tasks);
        }
    },

    wrapInScroll: function (tasks) {
        const styles = cleverapps.styles.HomeTasksWindow;

        const scroll = new cleverapps.UI.ScrollView(tasks, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_LINEAR
        });

        const size = {
            width: styles.width,
            height: Math.min(styles.height, tasks.height)
        };
        scroll.setContentSize2(size);
        this.content.addChild(scroll);
        scroll.setPositionRound(this.content.width / 2, this.content.height - size.height / 2);
    },

    createTask: function (furniture) {
        const styles = cleverapps.styles.HomeTasksWindow.task;

        const task = new cc.Scale9Sprite(bundles.home_task_window.frames.book_window_task);
        task.setContentSize2(styles);

        const text = cleverapps.UI.generateOnlyText(Messages.get(furniture.getInfo().title), cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);
        text.fitTo(undefined, styles.text.height);
        const button = this.createTaskButton(furniture);

        const icon = this.createTaskIcon(furniture);
        task.addChild(icon);
        icon.setPositionRound(styles.furniture);

        task.addChild(text);
        text.setPositionRound(styles.text);

        task.addChild(button);
        button.setPositionRound(styles.button);

        return task;
    },

    createFurnitureIcon: function (furniture) {
        const view = new cleverapps.Spine(bundles[`unit_${furniture.unit.code}`].jsons[`unit_${furniture.unit.code}_1`]);
        view.setAnimation(0, "animation", true);
        return view;
    },

    createTaskIcon: function (furniture) {
        const styles = cleverapps.styles.HomeTasksWindow.task.furniture;
        const iconBg = cleverapps.UI.createScale9Sprite(bundles.home_task_window.frames.task_window_task_iconBg);
        iconBg.setContentSize2(styles.bg);

        const furnitureView = this.createFurnitureIcon(furniture);
        cleverapps.UI.fitToBox(furnitureView, {
            width: iconBg.width - styles.offsetX,
            height: iconBg.height - styles.offsetY
        });
        iconBg.addChild(furnitureView);
        furnitureView.setPositionRound({ align: "center" }, { align: "center" });

        if (furniture.canBeUpgraded()) {
            const light = new cleverapps.Spine(bundles.home_task_window.jsons.task_icon_light_json);
            light.setAnimation(0, "animation", true);
            iconBg.addChild(light);
            light.setPositionRound(iconBg.width / 2, iconBg.height / 2);
        }

        return iconBg;
    },

    createTaskButton: function (furniture) {
        const styles = cleverapps.styles.HomeTasksWindow.task.button;
        const canUpgrade = furniture.canBeUpgraded();
        let msg = furniture.getInfo().cost;

        if (!canUpgrade) {
            msg = `${cleverapps.meta.logic.stars}/${msg}`;
        }

        return new cleverapps.UI.Button({
            text: `${msg}%%`,
            icons: {
                "%%": bundles.menubar.frames.menubar_metha_stars
            },
            height: styles.height,
            width: styles.width,
            type: canUpgrade ? cleverapps.styles.UI.Button.Images.small_button_green : cleverapps.styles.UI.Button.Images.small_button_blue,
            onClicked: this.onPress.bind(this, furniture, canUpgrade)
        });
    },

    onPress: function (furniture, canUpgrade) {
        this.close();
        if (canUpgrade) {
            cleverapps.focusManager.distract({
                focus: `NextStage.${furniture.id}.${furniture.getStage()}`,
                action: function (f) {
                    Homefix.currentHomefix.moveNext(furniture, f);
                }
            });
        } else {
            cleverapps.centerHint.createTextHint("Home.collectStars");
        }
    },

    listBundles: function () {
        return ["home_task_window"];
    }
});

cleverapps.styles.HomeTasksWindow = {
    mouseScrollSpeed: 400,
    margin: 25,
    width: 560,
    height: 650,

    padding: {
        x: 0,
        y: 20
    },

    springBg: {
        x: { align: "center" },
        y: { align: "top", dy: 18 },
        amount: 7,
        margin: 35
    },

    task: {
        width: 530,
        height: 120,

        furniture: {
            x: { align: "left", dx: 15 },
            y: { align: "center", dy: 2 },

            bg: {
                width: 90,
                height: 90
            },

            offsetX: 20,
            offsetY: 5
        },

        text: {
            width: 265,
            height: 120,
            x: { align: "center", dx: -14 },
            y: { align: "center" }
        },

        button: {
            width: 125,
            height: 65,

            x: { align: "right", dx: -15 },
            y: { align: "center" }
        }
    },

    window: {
        Background: {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            image: bundles.home_task_window.frames.book_window
        }
    }
};