/**
 * Created by razial on 11.03.2025.
 */

const HomefixLocationAdapter = function (homefix) {
    this.homefix = homefix;
};

HomefixLocationAdapter.prototype.load = function (key) {
    switch (key) {
        case "fogs": return this.loadFogs();
    }
};

HomefixLocationAdapter.prototype.save = function (key, info) {
    switch (key) {
        case "fogs": this.saveFogs(info); break;
    }
};

HomefixLocationAdapter.prototype.remove = function () {

};

HomefixLocationAdapter.prototype.loadUnit = function (x, y) {
    const fakeUnit = this.homefix.map.fogs.getFakeUnit(x, y);

    if (fakeUnit) {
        const saveUnit = this.homefix.storedUnits[fakeUnit.code];
        if (saveUnit) {
            return { code: fakeUnit.code, ...saveUnit };
        } if (Families[fakeUnit.code || fakeUnit.head.code].units[0].invisible) {
            return fakeUnit;
        }
    }
};

HomefixLocationAdapter.prototype.saveUnit = function (x, y, info) {
    const data = {};
    data.stage = info.stage;

    if (info.choice !== undefined) {
        data.choice = info.choice;
    }

    this.homefix.storedUnits[info.code] = data;
    this.homefix.storeSave();
};

HomefixLocationAdapter.prototype.removeUnit = function (x, y) {
    const fakeUnit = this.homefix.map.fogs.getFakeUnit(x, y);

    if (fakeUnit) {
        const saveUnit = this.homefix.storedUnits[fakeUnit.code];
        if (saveUnit) {
            delete this.homefix.storedUnits[fakeUnit.code];
            this.homefix.storeSave();
        }
    }
};

HomefixLocationAdapter.prototype.loadFogs = function () {
    return this.homefix.storedFogs || [];
};

HomefixLocationAdapter.prototype.saveFogs = function (info) {
    this.homefix.storedFogs = info;
    this.homefix.storeSave();
};
