/**
 * Created by iamso on 25.03.2020.
 */

const NewTaskAnimation = cc.Node.extend({
    ctor: function (furniture, parent, f) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.addBg();
        this.addTitle();
        this.addDescription(furniture);
        this.addIcon(furniture);

        this.setPositionRound(cleverapps.styles.NewTaskAnimation.hidePosition);

        parent.addChild(this);
        this.show();
        this.hide(f);
    },

    showItem: function (item, delay) {
        const scale = item.getScale();

        item.setScale(scale * 0.5);

        item.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.Show(),
            new cc.ScaleTo(0.3, scale).easing(cc.easeBackOut())
        ));
    },

    show: function () {
        const styles = cleverapps.styles.NewTaskAnimation.showPosition;
        const showPosition = this.calculatePositionRound(styles.x, styles.y);

        this.bgAnimation.setAnimationAndIdleAfter("open", "idle");
        this.bgBottom.setAnimationAndIdleAfter("open", "idle");

        this.runAction(new cc.MoveTo(0.6, showPosition.x, showPosition.y).easing(cc.easeBackOut())).setFinalize(() => {
            this.setPositionRound(styles);
        });

        this.bgBottom.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            StandartAnimations.complete(this.bgBottom)
        ));

        this.showItem(this.title, 0.65);
        this.showItem(this.unitIcon, 0.95);
        this.showItem(this.description, 0.95);
    },

    hideItem: function (item, delay) {
        const scale = item.getScale();

        item.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.ScaleTo(0.2, scale * 0.5).easing(cc.easeBackIn()),
            new cc.Hide()
        ));
    },

    hide: function (f, delay) {
        delay = delay || 1.8;
        f = f || function () {};

        this.hideItem(this.title, delay);
        this.hideItem(this.description, delay);
        this.hideItem(this.unitIcon, delay);

        const target = cleverapps.scenes.getRunningScene().tasksIcon;
        const targetPosition = target.getPosition();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay + 0.2),
            new cc.CallFunc(() => {
                this.bgAnimation.setAnimation(0, "close", false);
                this.bgBottom.setAnimation(0, "close", false);

                cleverapps.aims.showTarget(target, {
                    duration: 2000
                });
            }),
            new cc.MoveTo(0.5, targetPosition.x, targetPosition.y).easing(cc.easeBackIn()),
            new cc.CallFunc(() => {
                target.target.addNewTaskAnimation();
                f();
            })
        )).setFinalize(() => {
            this.removeFromParent();
        });
    },

    addBg: function () {
        const animation = this.bgAnimation = new cleverapps.Spine(bundles.homefix.jsons.hometask_newtask_json);
        this.setContentSize(animation.getContentSize());
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);

        const bottomPart = this.bgBottom = new cleverapps.Spine(bundles.homefix.jsons.hometask_newtask_part_json);
        bottomPart.setLocalZOrder(1);
        this.addChild(bottomPart);
        bottomPart.setPositionRound(this.width / 2, this.height / 2);
    },

    addTitle: function () {
        const title = this.title = cleverapps.UI.generateTTFText(Messages.get("New task"), cleverapps.styles.FONTS.WINDOW_TITLE_TEXT);
        title.setAnchorPoint(0.5, 0.5);
        title.fitTo(cleverapps.styles.NewTaskAnimation.title.width, cleverapps.styles.NewTaskAnimation.title.height);
        this.addChild(title);
        title.setPositionRound(cleverapps.styles.NewTaskAnimation.title);
        title.setVisible(false);
    },

    addDescription: function (furniture) {
        const styles = cleverapps.styles.NewTaskAnimation.description;
        const description = this.description = cleverapps.UI.generateTTFText(Messages.get(furniture.getInfo() ? furniture.getInfo().title : "furniture"), {
            size: 35,
            color: new cc.Color(0xac, 0x50, 0x22, 0xff)
        });

        description.setAnchorPoint(0.5, 0.5);
        description.setDimensions(styles.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setPositionRound(styles);
        description.setLocalZOrder(2);
        description.setVisible(false);
        description.fitTo(styles.width, styles.height);
        this.addChild(description);
    },

    addIcon: function (furniture) {
        const styles = cleverapps.styles.NewTaskAnimation.icon;
        const unitIcon = this.unitIcon = UnitView.getUnitImage(furniture.unit);
        cleverapps.UI.fitToBox(unitIcon, styles);
        unitIcon.setPositionRound(styles);
        unitIcon.setVisible(false);
        this.addChild(unitIcon);
    }
});

cleverapps.styles.NewTaskAnimation = {
    hidePosition: {
        x: { align: "left", dx: -700 },
        y: { align: "center", dy: 0 }
    },

    showPosition: {
        x: { align: "left", dx: 50 },
        y: { align: "center", dy: 0 }
    },

    title: {
        width: 245,
        height: 50,
        x: { align: "center" },
        y: { align: "top", dy: -67 }
    },

    icon: {
        width: 115,
        height: 115,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    },

    description: {
        width: 230,
        height: 100,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 60 }
    }
};