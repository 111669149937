/**
 * Created by mac on 2/3/20
 */

const HomefixLocation = function (locationId) {
    BaseLocation.call(this, "homefix", locationId);

    this.slot = Meta.SLOT_MAIN;
};

HomefixLocation.prototype = Object.create(BaseLocation.prototype);
HomefixLocation.prototype.constructor = HomefixLocation;

HomefixLocation.prototype.complete = function () {
    this.completed = true;
};

HomefixLocation.prototype.isCompleted = function () {
    return this.completed;
};

BaseLocation.prototype.gamePlayed = function (game) {
    game.rewards.metaStar = game.level.isHard() ? 3 : 1;

    cleverapps.user.incLevel();
};

HomefixLocation.prototype.getProgress = function () {
    let goal = 0;
    let current = 0;
    Homefix.currentHomefix.getFurniture().forEach((furniture) => {
        furniture.stages.forEach((stage) => {
            goal += stage.cost;
            if (furniture.id <= Homefix.currentHomefix.furnitureId) {
                current += stage.cost;
            }
        });
    });
    return current / goal * 100;
};

HomefixLocation.GetMainScene = function () {
    return HomefixScene;
};
